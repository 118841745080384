.environmentSwitch {
  color: var(--color-secondary);
  gap: var(--space-2);
  margin-left: var(--space-4);
  align-items: center;
  font-weight: var(--font-weight-medium);
}

.environmentBanner {
  background-color: var(--accent-indicator);
  color: var(--accent-contrast);
  padding: var(--space-2);
  font-weight: var(--font-weight-medium);
  z-index: 1000;
  position: sticky;
  top: 0;
}
